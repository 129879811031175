import Image from "next/image";
import Benefit1 from "../../public/img/benefit-1.png";
import Benefit2 from "../../public/img/benefit-2.png";
import Benefit3 from "../../public/img/benefit-3.png";
import Benefit4 from "../../public/img/benefit-4.png";
import Benefit5 from "../../public/img/benefit-5.png";

const benefitItems = [
  {
    title: "Geschultes Servicepersonal",
    image: Benefit1,
  },
  {
    title: "Rabatte für weitere Aufträge",
    image: Benefit2,
  },
  {
    title: "Flexible Terminoptionen",
    image: Benefit3,
  },
  {
    title: "Telefonische Auftragsannahme",
    image: Benefit4,
  },
  {
    title: "Online Preisrechner",
    image: Benefit5,
  },
];

const BenefitsSection = () => {
  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold text-blue-800 text-center">Nutze jetzt die Vorteile von mytierversand.de</h2>
      <ul className="flex justify-between mt-12">
        {benefitItems.map(({ title, image }) => (
          <li className="flex flex-col justify-center items-center">
            <p className="mb-4">{title}</p>
            <Image src={image} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export { BenefitsSection };
