import Link from "next/link";
import { useRouter } from "next/router";

const navItems = [
  {
    name: "Start",
    href: "/",
  },
  {
    name: "Über Uns",
    href: "/about-us",
  },
  {
    name: "Sendungsverfolgung",
    href: "/tracking",
  },
  {
    name: "Service Center",
    href: "/contact",
  },
  {
    name: "Häufige Fragen",
    href: "/faq",
  },
];

interface NavigationProps {
  className?: string;
}

const Navigation = ({ className }: NavigationProps) => {
  const router = useRouter();
  return (
    <nav className={className}>
      <ul className="flex  divide-x bg-gray-100 rounded-xl overflow-hidden">
        {navItems.map(({ name, href }) => (
          <Link href={href}>
            <a>
              <li
                className={`py-4 px-6 text-center hover:bg-yellow-500 hover:text-white transition-all duration-300 ease-in-out ${
                  router.pathname === href ? "bg-yellow-500 text-white" : undefined
                }`}
              >
                {name}
              </li>
            </a>
          </Link>
        ))}
      </ul>
    </nav>
  );
};

export { Navigation };
