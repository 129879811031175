import Head from "next/head";
import { Footer } from "./sections/footer";
import { Header } from "./sections/header";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="text-gray-700 flex flex-col items-center h-full">
      <Head>
        <title>Günstiger Tierversand | mytierversand.de</title>
        <meta property="og:title" content="Günstiger Tierversand | mytierversand.de" key="title" />
        <meta
          property="og:description"
          name="description"
          content="Wir versenden Ihre Tiere schnell und sicher. Mit unserem Versandkonfigurator buchen Sie Ihren Tierersand in wenigen Augenblicken"
        />
        <meta
          name="keywords"
          content="Tierversand, Tiertransport, Tiere versenden, Tiere verschicken, Lebendtierversand, Günstiger Tierversand"
        />
      </Head>
      <div className="max-w-7xl w-full py-6">
        <Header />
        <main className="mt-4">{children}</main>
      </div>

      <Footer className="mt-auto" />
    </div>
  );
};

export { Layout };
