import Image from "next/image";
import ContactPerson from "../../public/img/contact-person.png";
import Puzzle from "../../public/img/ku.png";
import Telephone from "../../public/img/tel.png";
import MagnifynigGlass from "../../public/img/lup.png";

const ContactSection = () => {
  return (
    <div className="flex p-6 justify-between">
      <div>
        <Image src={ContactPerson} />
      </div>
      <div className="text-center max-w-sm">
        <div>
          <div className="font-bold">Haben Sie noch Fragen?</div>
          <div className="mt-4">
            Dann rufen Sie uns an oder nutzen Sie unten Links den Livechat. Wir stehen Ihnen gerne zu Verfügung.
          </div>
        </div>
        <div className="mt-8">
          <div className="font-bold text-xl">0 71 41 − 37 391 35</div>
          <div className="mt-4">Die wichtigsten Fragen und Antworten zu den günstigen Versandtarifen finden Sie hier: FAQ´s</div>
        </div>
      </div>
      <div className="space-y-8">
        <div className="flex items-center">
          <Image src={Puzzle} />
          So funktionierts
        </div>
        <div className="flex items-center">
          <Image src={MagnifynigGlass} />
          Sendungsverfolgung
        </div>
        <div className="flex items-center">
          <Image src={Telephone} />
          Kundenservice
        </div>
      </div>
    </div>
  );
};

export { ContactSection };
