import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { useShipment } from "../../lib/booking";
import { gql, useApolloClient } from "@apollo/client";
import {
  zipCodeExists,
  zipCodeExistsVariables,
} from "../../__generated__/zipCodeExists";
import { SelectInput } from "../select-input";
import { ContentClass } from "../../__generated__/globalTypes";
import { useTranslation } from "react-i18next";
import { last } from "lodash";

interface HeroBookEnterDataProps {
  defaultContentClass?: ContentClass;
  onCalculatePrice: () => void;
}

interface FormTypes {
  senderCountry: string;
  senderZip: string;
  receiverCountry: string;
  receiverZip: string;
  contentClass: ContentClass;
}

export const CHECK_ZIP = gql`
  query zipCodeExists($country: String!, $zipCode: String!) {
    zipCodeExists(country: $country, zipCode: $zipCode)
  }
`;

const HeroBookEnterData: React.FC<HeroBookEnterDataProps> = ({
  defaultContentClass,
  onCalculatePrice,
}) => {
  const tld = typeof window === "undefined" ? undefined : last(window.location.hostname.split("."));
  const defaultCountry = tld?.toLowerCase() === "at" ? "AT" : "DE";
  const { t } = useTranslation();
  const { shipment, setInitialInfo } = useShipment();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState,
    trigger,
    setValue,
  } = useForm<FormTypes>();
  const apolloClient = useApolloClient();
  const senderCountry = watch(
    "senderCountry",
    shipment?.sender?.country ?? defaultCountry
  );
  const receiverCountry = watch(
    "receiverCountry",
    shipment?.receiver?.country ?? defaultCountry
  );

  useEffect(() => {
    setValue("senderCountry", shipment?.sender?.country ?? defaultCountry);
    setValue("receiverCountry", shipment?.receiver?.country ?? defaultCountry);
    setValue("senderZip", shipment?.sender?.zipcode ?? "");
    setValue("receiverZip", shipment?.receiver?.zipcode ?? "");
  }, []);

  useEffect(() => {
    if (formState.isSubmitted && !formState.isSubmitSuccessful) {
      trigger();
    }
  }, [
    formState.isSubmitted,
    formState.isSubmitSuccessful,
    senderCountry,
    receiverCountry,
  ]);

  const checkZip = (country: string, zipCode: string) => {
    return apolloClient.query<zipCodeExists, zipCodeExistsVariables>({
      query: CHECK_ZIP,
      fetchPolicy: "cache-first",
      errorPolicy: "all",
      variables: {
        country,
        zipCode: zipCode.trim(),
      },
    });
  };

  const onSubmit = (data: FormTypes) => {
    typeof ga !== "undefined" && ga("send", "event", "calculator", "calculate-price")
    setInitialInfo(data);
    onCalculatePrice();
  };

  const errorMessage = Object.values(errors).map((e) => e.message)[0];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
      <div className="grid grid-cols-1 xs:grid-cols-2 gap-2">
        <div className="xs:col-span-2">
          <SelectInput
            error={errors.contentClass}
            ref={register({
              required: {
                value: true,
                message: "Welche Tierklasse wollen Sie versenden",
              },
              validate: (v) =>
                Object.values(ContentClass).includes(v)
                  ? undefined
                  : "Tierkategorie auswählen",
            })}
            tabIndex={1}
            name="contentClass"
            placeholder="Bitte Auswählen"
            defaultValue={
              shipment?.parcels?.[0].contentClass ?? defaultContentClass
            }
            options={Object.values(ContentClass).map((contentClass) => ({
              value: contentClass,
              label: t(`contentClass.${contentClass}`),
            }))}
          >
            Tierkategorie
          </SelectInput>
        </div>

        <div className="space-y-2">
          <label className="block">
            <div>Sender</div>
            <select
              ref={register({
                required: true,
              })}
              defaultValue={senderCountry}
              tabIndex={2}
              name="senderCountry"
              className="w-full min-w-0 px-2 py-1 rounded focus:outline-none border border-gray-300"
            >
              <option value="DE">Deutschland</option>
              <option value="AT">Österreich</option>
              <option value="BE">Belgien</option>
            </select>
          </label>
          <label className="block">
            <div
              className={`flex rounded ${errors.senderCountry || errors.senderZip
                  ? "border-2 border-red-500"
                  : "border border-gray-300"
                }`}
            >
              <input
                ref={register({
                  validate: async (zip) => {
                    const zipExists = await checkZip(senderCountry, zip);
                    if (zipExists.data.zipCodeExists) {
                      return undefined;
                    }
                    return "Sender Postleitzahl unbekannt";
                  },
                  required: {
                    value: true,
                    message: "Wie lautet die Sender Postleitzahl?",
                  },
                })}
                defaultValue={shipment?.sender?.zipcode}
                tabIndex={4}
                name="senderZip"
                className="w-full min-w-0 px-2 py-1 rounded focus:outline-none appearance-none"
                placeholder="Postleitzahl"
              />
            </div>
          </label>
        </div>

        <div className="space-y-2">
          <label className="block">
            <div>Empfänger</div>
            <select
              ref={register({
                required: true,
              })}
              defaultValue={receiverCountry}
              name="receiverCountry"
              className="w-full min-w-0 px-2 py-1 rounded focus:outline-none border border-gray-300"
              tabIndex={3}
            >
              <option value="DE">Deutschland</option>
              <option value="AT">Österreich</option>
              <option value="BE">Belgien</option>
            </select>
          </label>
          <label className="block">
            <div
              className={`flex rounded ${errors.receiverCountry || errors.receiverZip
                  ? "border-2 border-red-500"
                  : "border border-gray-300"
                }`}
            >
              <input
                ref={register({
                  validate: async (zip) => {
                    const zipExists = await checkZip(receiverCountry, zip);
                    if (zipExists.data.zipCodeExists) {
                      return undefined;
                    }
                    return "Empfänger Postleitzahl unbekannt";
                  },
                  required: {
                    value: true,
                    message: "Wie lautet die Empfänger Postleitzahl?",
                  },
                })}
                tabIndex={5}
                defaultValue={shipment?.receiver?.zipcode}
                name="receiverZip"
                className="w-full min-w-0 px-2 py-1 rounded focus:outline-none appearance-none"
                placeholder="Postleitzahl"
              />
            </div>
          </label>
        </div>
      </div>
      {errorMessage && <div className="mt-2 text-red-600">{errorMessage}</div>}
      <button
        type="submit"
        className="mt-3 col-span-2 items-center px-2 py-1 border border-transparent text-lg font-semibold rounded shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
      >
        Preis berechnen
      </button>
    </form>
  );
};
export { HeroBookEnterData };
