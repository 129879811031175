import Image from "next/image";
import Logo from "../../public/img/logo.jpeg";
import { Navigation } from "./navigation";

const Header = () => {
  return (
    <header>
      <div className="flex justify-between">
        <div className="flex-shrink-0">
          <Image src={Logo} />
        </div>
        <div className="">
          <p className="text-sm">
            Haben Sie Fragen? <span className="text-yellow-500 font-semibold">0 71 41 / 37 391 35</span>
          </p>
          <p className="text-xs">Montag bis Donnerstags, 11:00 - 13:00 Uhr und 15:00-17:00 Uhr</p>
          <p className="text-xs">Freitag, 11:00 - 13:00 Uhr</p>
          <p className="text-xs">
            Außerhalb unserer Servicezeiten senden Sie uns bitte eine email an: kontakt@mytierversand.de
          </p>
        </div>
      </div>
      <Navigation className="mt-8" />
    </header>
  );
};

export { Header };
