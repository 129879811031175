import React, { useState } from "react";
import Head from "next/head";
import { Container } from "../container";
import { HeroBookEnterData } from "./hero-book-enter-data";
import { HeroBookPrice } from "./hero-book-price";
import { ContentClass } from "../../__generated__/globalTypes";

interface HeroBookProps {
  h1?: string;
  defaultContentClass?: ContentClass;
}

const HeroBook: React.FC<HeroBookProps> = ({ h1, defaultContentClass }) => {
  const [showPrice, setShowPrice] = useState(false);

  return (
    <Container
      maxWidth="1200px"
      style={{ backgroundPosition: "center", backgroundSize: "cover", backgroundImage: "url(/img/booking-bg.png)" }}
      containerClassName="hero-image"
    >
      <div className="flex flex-wrap md:flex-no-wrap justify-between mt-24 md:mt-40 mb-24 md:mb-32">
        <div
          className="w-full sm:w-96 p-4 rounded shadow-lg mt-4"
          style={{ backgroundColor: "rgba(255,255,255, .85)" }}
        >
          {showPrice ? (
            <HeroBookPrice onReset={() => setShowPrice(false)} />
          ) : (
            <HeroBookEnterData onCalculatePrice={() => setShowPrice(true)} defaultContentClass={defaultContentClass} />
          )}
        </div>

        <div className="w-full md:w-1/2">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-semibold leading-none text-yellow-500 text-shadow-lg">
            {h1 ?? "Das ist mein tierversand"}
          </h2>
          <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold leading-tight text-yellow-500 text-shadow-lg mt-2">
            Ihre Lieblinge in guten Händen!
          </h3>
        </div>
      </div>
    </Container>
  );
};
export { HeroBook };
