interface SendingWithUsSectionProps {
  hideTitle?: boolean;
}

const SendingWithUsSection = ({ hideTitle }: SendingWithUsSectionProps) => {
  return (
    <div className="space-y-5">
      {!hideTitle && <h2 className="text-2xl font-medium text-blue-800">Warum mit mytierversand.de versenden?</h2>}
      <p>
        mytierversand.de ist ein unabhängiges Versandunternehmen für Privat- und Geschäftskunden, welches sich auf den
        Versand von Reptilien, Fischen, Kleinsäugern, Ziervögel und Geflügel spezialisiert hat. Wir bieten unseren
        Kunden für jeden Tierversand die individuell optimale Lösung zu einem besonders attraktiven Preis. Durch unser
        einfach und umfangeich gestaltetes Online-Angebot und Online-Auftragsformular können Sie nicht nur Zeit und
        Nerven, sondern auch richtig Geld sparen. Profitieren Sie von unserem Know-how aus langjähriger Erfahrung im
        Bereich Tierversand! Unsere kompetenten, freundlichen und speziell für den Tierversand geschulten Mitarbeiter
        warten auf Ihre Anfragen und kümmern sich gerne persönlich und schnellstmöglich um Ihr Anliegen.
      </p>
      <h6 className="font-bold">Online-Preisrechner</h6>
      <p>
        Mit unserem Online-Preisrechner haben Sie die Möglichkeit, die Kosten für Ihren Reptilien- oder Fischversand
        schnell und einfach zu berechnen. Die Preisberechnung erfolgt in vier Schritten und präsentiert Ihnen sofort den
        günstigen Versandpreis. Hierzu müssen Sie lediglich die Postleitzahl der Abhol- und Lieferort, die Maße der
        Verpackung sowie das Gewicht der Sendung eingeben. Anhand dieser Angaben werden alle verfügbaren Versandtarife
        zum bestmöglichen Preis ermittelt.
      </p>
      <h6 className="font-bold">Rasche und unkomplizierte Versandabwicklung</h6>
      <p>
        Wir wickeln Ihren Tierversand so schnell und unkompliziert wie möglich ab. Sie haben die Möglichkeit einen
        bestimmten Versandtag und ein Zeitfenster von drei Stunden auszuwählen. Dabei können Sie nicht nur mit der
        idealen Versandlösung, sondern auch mit rabattierten Preisen rechnen. Ihre Versandaufträge können Sie bequem
        online aufgeben. Wir werden uns dann so rasch wie möglich um Ihre Pakete kümmern und diese in den Versand
        bringen.
      </p>
      <h6 className="font-bold">Umfangreicher Kundenservice</h6>
      <p>
        Wir möchten, dass Sie sich als Kunde bei uns in guten Händen und rundum bestens beraten fühlen. Deshalb legen
        wir besonderen Wert auf hervorragenden Service und direkten Kundenkontakt. Unsere geschulten und freundlichen
        Mitarbeiter kümmern sich persönlich um Ihre Anliegen und sind stets um Ihre Zufriedenheit bemüht. Über unsere
        Hotline können Sie Fragen zu unserem Angebot stellen und Versandaufträge erteilen. Darüber hinaus sind wir auch
        auf Social Media Seiten vertreten. So halten wir unsere Kunden nicht nur stets mit allen Neuigkeiten auf dem
        Laufenden, sondern sind auch für Anregungen, Lob und Kritik erreichbar.
      </p>
      <h6 className="font-bold">Flexible Zahlungsmöglichkeiten</h6>
      <p>
        Wir bieten Ihnen zahlreiche verschiedene Zahlungsmöglichkeiten. So können Sie bei uns mit PayPal, vorab
        Überweisung oder Bar bezahlen.
      </p>
      <h6 className="font-bold">Unsere Kunden</h6>
      <p>
        Das Angebot von uns richtet sich sowohl an Privat- als auch an Gewerbekunden und bietet allen maßgeschneiderte
        Versandlösungen. Egal, ob Sie privat einen einzelnen Tierversand möchten oder ob Sie als Händler ein ganz
        speziellen Versandwunsch haben, wir versuchen stets, die optimale Versandmöglichkeit für Sie zu finden.
      </p>
      <h6 className="font-bold">Alles versenden</h6>
      <p>
        Mit mytierversand.de können Sie nicht nur Tiere versenden. Über unsere Partnerseite www.gardow-logistics.de
        können Sie auch herkömmliche Pakete und Paletten versenden, sowie Sperrgut und individuelle Sendungen in Auftrag
        geben. Egal, ob Privat- oder Geschäftskunde, wir bearbeiten grundsätzlich jeden Versandauftrag und versuchen
        stets die optimale Versandlösung zum günstigsten Preis zu finden.
      </p>
      <p className="font-bold">Wir freuen uns auf Sie!</p>
    </div>
  );
};

export { SendingWithUsSection };
