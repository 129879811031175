import { Layout } from "../components/layout";
import { BenefitsSection } from "../components/sections/benefits-section";
import { ContactSection } from "../components/sections/contact-section";
import { HeroBook } from "../components/sections/hero-book";
import { SendingWithUsSection } from "../components/sections/sending-with-us-section";
import { withApollo } from "../lib/with-apollo";

const Index: React.FC = () => {
  return (
    <Layout>
      <HeroBook />
      <BenefitsSection />
      <SendingWithUsSection />
      <ContactSection />
    </Layout>
  );
};

export default withApollo()(Index);
