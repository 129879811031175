import Link from "next/link";

const footerNav = [
  {
    title: "mytierversand.de",
    items: [
      {
        name: "Unternehmen",
        href: "/about-us",
      },
      {
        name: "Geschäftskunden",
        href: "/business",
      },
      {
        name: "AGB",
        href: "/terms",
      },
      {
        name: "Datenschutz",
        href: "/privacy",
      },
      {
        name: "Widerrufsbelehrung",
        href: "/cancellation",
      },
      {
        name: "Impressum",
        href: "/legal-notice",
      },
    ],
  },
  {
    title: "Online Service",
    items: [
      {
        name: "Preisrechner",
        href: "",
      },
      {
        name: "Online Auftrag",
        href: "",
      },
      {
        name: "Häufige Fragen",
        href: "/faq",
      },
      {
        name: "Download",
        href: "/contact",
      },
      {
        name: "Aktuelles",
        href: "",
      },
    ],
  },
  {
    title: "Beliebte Suche",
    items: [
      {
        name: "Nationaler Tierversand",
        href: "",
      },
      {
        name: "Reptilienversand",
        href: "",
      },
      {
        name: "Insektenversand",
        href: "",
      },
      {
        name: "Fischversand",
        href: "",
      },
    ],
  },
  {
    title: "Kundenservice",
    items: [
      {
        name: "Sendungsverfolgung",
        href: "/tracking",
      },
      {
        name: "Kontakt",
        href: "/contact",
      },
      {
        name: "Social Media",
        href: "",
      },
      {
        name: "@mytierversand.de folgen",
        href: "",
      },
    ],
  },
  {
    title: "Social Media",
    items: [
      {
        name: "@mytierversand.de folgen",
        href: "",
      },
    ],
  },
];

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  return (
    <div className={`bg-gray-300 w-full flex justify-center items-center ${className ?? ""}`}>
      <div className="max-w-7xl w-full py-4">
        <nav className="flex justify-between flex-wrap">
          {footerNav.map(({ title, items }) => (
            <div className="text-sm px-4">
              <h6 className="font-bold">{title}</h6>
              <ul className="mt-2">
                {items.map(({ name, href }) => (
                  <Link href={href}>
                    <a className="hover:underline">
                      <li>{name}</li>
                    </a>
                  </Link>
                ))}
              </ul>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export { Footer };
